import { Event } from '../Event'
import { ViewerContext } from '../../constants'
import { SellableProperties } from '../types/consumer'

export interface ProductViewedEventProperties extends SellableProperties {
  activity_id: string
  ref?: string
}

export class ProductViewedEvent extends Event<ProductViewedEventProperties> {
  static displayName = 'Product Viewed'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
