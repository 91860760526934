import TrackingClient from '../TrackingClient'
import { Event, OptionalEventProperties } from '../events'
import { Identity, GenericTracker } from '../types/tracker'

export interface ConnectPartnerTrackingEventData {
  eventDisplayName: string
  eventParams?: Record<string, any>
}

/**
 * Log events to a connect partner's internal tracking system (i.e., their Google Analytics).
 *
 *  - This is only for the connect-app, which is rendered inside an iframe on the connect partner's website.
 *  - All events are tracked in the same manner (i.e., there's no functional difference between `identify` & `track`, they're both tracked like "events").
 *  - The `onTrackRequested` construction parameter sends a postMessage to the parent document, where its payload is handled.
 */
export default class ConnectPartnerTracker implements GenericTracker {
  private _client: TrackingClient
  private _track: (eventData: ConnectPartnerTrackingEventData) => void

  public constructor(
    client: TrackingClient,
    onTrackRequested: (eventData: ConnectPartnerTrackingEventData) => void
  ) {
    this._client = client
    this._track = onTrackRequested
  }

  public track(event: Event<OptionalEventProperties>) {
    const eventDisplayName = `Curated Connect Event: ${event.displayName}`
    const eventParams = Object.assign({ _id: event.id }, event.properties)
    this._track({ eventDisplayName, eventParams })
  }

  public identify(identity: Identity) {
    const eventDisplayName = 'Curated Connect Event: Consumer Identified'
    const eventParams = Object.assign(
      { sessionId: this._client.sessionId, browserId: this._client.browserId },
      identity
    )
    this._track({ eventDisplayName, eventParams })
  }

  /**
   * `GenericTracker` methods not overridden by the `ConnectPartnerTracker`:
   *
   * public touch() {}
   * public addTraits(newTraits: UserTraits) {}
   * public setDepartment(departmentSlug: string) {}
   * public setPageKey(pageKey: string) {}
   * public reset() {}
   * public disable() {}
   * public enable() {}
   */
}
