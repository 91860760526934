import type { apiObject, apiOptions } from 'rudder-sdk-js'
import * as rudderanalytics from 'rudder-sdk-js'
import { Identity, GenericTracker, TrackingContext } from '../types/tracker'
import { TouchPointAttributes } from '../types/touches'
import TrackingClient from '../TrackingClient'
import { ViewerContext } from '../constants'
import { Event, OptionalEventProperties } from '../events/Event'
import * as Events from '../events'
import uuidv4 from 'uuid/v4'

export interface RudderstackTrackerOptions {
  writeKey: string
  dataPlaneUrl: string
}

export default class RudderstackTracker implements GenericTracker {
  private client: TrackingClient

  public constructor(options: RudderstackTrackerOptions, client: TrackingClient) {
    this.client = client

    rudderanalytics.load(options.writeKey, options.dataPlaneUrl)

    rudderanalytics.ready(() => {
      if (this.client.sessionId) {
        rudderanalytics.setAnonymousId(this.client.sessionId)
      }
    })
  }

  /**
   * Associate a tracked user with a persistent User ID.
   */
  public identify(identity: Identity, context: TrackingContext) {
    const identifyId = uuidv4()

    const options = this.getRudderstackTrackingOptions(context, identifyId)

    rudderanalytics.setAnonymousId(context.sessionId)
    rudderanalytics.identify(identity.userId, context.traits as unknown as apiObject, options)
  }

  /**
   * Disassociate the tracked user from the previously identified user ID, and
   *   clear any other user-specific state/traits.
   */
  public reset() {
    rudderanalytics.reset(true)
  }

  /**
   * Mark the start of a new session and associate it with marketing/acquisition data
   *
   * See: https://dealdotcom.atlassian.net/wiki/spaces/ENGINEERING/pages/13467664/Sessionization
   *
   * @param attributes Acquisition attributes associated with this touchpoint.
   */
  public touch(attributes: TouchPointAttributes, context: TrackingContext) {
    const touchId = uuidv4()

    this.trackRudderstackEvent(touchId, 'Touch', context, attributes as apiObject)
  }

  /**
   * Handle events as they are tracked.
   *
   * Rudderstack treats page views as special events, so we forward events to
   *   either rudderanalytics.track or rudderanalytics.page as appropriate.
   */
  public track(event: Event<OptionalEventProperties>, context: TrackingContext) {
    if (event instanceof Events.PageViewedEvent) {
      this.trackRudderstackPage(event, context)
    } else {
      this.trackRudderstackEvent(
        event.id,
        event.displayName,
        context,
        event.properties as apiObject
      )
    }
  }

  /**
   * A wrapper for the Rudderstack SDK `track` method. We only accept a single
   *   signature, rather than the overloaded signatures Rudderstack allows.
   *
   * See: https://www.rudderstack.com/docs/stream-sources/rudderstack-sdk-integration-guides/rudderstack-javascript-sdk/#track
   */
  private trackRudderstackEvent(
    eventId: string,
    eventDisplayName: string,
    context: TrackingContext,
    properties?: apiObject
  ) {
    const options = this.getRudderstackTrackingOptions(context, eventId)

    rudderanalytics.track(eventDisplayName, properties || {}, options)
  }

  /**
   * A wrapper for the Rudderstack SDK `page` method. We only accept a single
   *   signature, rather than the overloaded signatures Rudderstack allows.
   *
   * See: https://www.rudderstack.com/docs/stream-sources/rudderstack-sdk-integration-guides/rudderstack-javascript-sdk/#page
   */
  private trackRudderstackPage(event: Events.PageViewedEvent, context: TrackingContext) {
    const options = this.getRudderstackTrackingOptions(context, event.id)

    rudderanalytics.page((event.properties || {}) as unknown as apiObject, options)
  }

  /**
   * Attach some additional context to every Rudderstack call. We override
   *   Rudderstack's default `anonymousId` to use our `sessionId`.
   *
   * We also send last-touch attribution data with every tracking event, however
   *   last-touch attribution does not make sense for "initAppUrl" so we
   *   use first-touch for that attribute.
   *
   * Attribution data should be derived by our data warehouse rather than being
   *   tracked on the frontend. Sending this data along with individual tracking
   *   events is a bad practice and will be deprecated/removed eventually.
   */
  private getRudderstackTrackingOptions(context: TrackingContext, eventId: string): apiOptions {
    const firstTouchAttributes = context.attribution.firstTouch?.attributes || {}
    const lastTouchAttributes = context.attribution.lastTouch?.attributes || {}

    // N.B.: This is a confusing name: in this repo, "traits" refers to PII like
    //   "firstName" and "lastName" (see`src/traits`). Here, "traits" means user
    //   acquisition/attribution data. This is due to historical reasons and may
    //   change in the future.
    const attributionTraits = {
      ...lastTouchAttributes,
      initAppUrl: firstTouchAttributes.initAppUrl,
    }

    const traits = {
      ...attributionTraits,
      // We send `traits.event_id` to deduplicate events sent to Facebook client-side and server-side
      //   See: https://www.rudderstack.com/docs/destinations/streaming-destinations/fb-pixel/#event_id-method-recommended
      event_id: eventId,
      // We also send as many canonically supported Rudderstack traits with each event.
      //   See: https://www.rudderstack.com/docs/event-spec/standard-events/identify/#identify-traits
      address: context.traits.address,
      birthday: context.traits.dateOfBirth?.toISOString(),
      email: context.traits.email,
      firstName: context.traits.firstName,
      gender: context.traits.gender,
      lastName: context.traits.lastName,
      phone: context.traits.phone,
    }

    return {
      anonymousId: context.sessionId,
      context: {
        consumerId: context.consumerId,
        businessUserId: context.businessUserId,
        partnerUserId: context.partnerUserId,
        operatorId: context.operatorId,
        tenantId: context.tenantId,
        publisherId: context.publisherId,
        viewerContext: this.getHumanReadableUserViewerContext(context.viewerContext),
        application: context.application,
        department: context.department,
        pageKey: context.pageKey,
        parentUrl: context.connectWidgetParentUrl,
        traits,
      },
    }
  }

  private getHumanReadableUserViewerContext(viewerContext: ViewerContext): string {
    switch (viewerContext) {
      case ViewerContext.Consumer:
        return 'consumer'
      case ViewerContext.BusinessUser:
        return 'business_user'
      case ViewerContext.PartnerUser:
        return 'partner_user'
      case ViewerContext.Operator:
        return 'operator'
    }
  }
}
